import { useDisclosure } from '@mantine/hooks';
import { Modal, ModalClassNamesType } from '@ui/core';
import PlaceholderBox from './PlaceholderBox';
import SearchBox from './SearchBox';

const styles: ModalClassNamesType = {
  header: 'p-0',
  close: 'right-0 top-0',
  inner: 'p-10 mobile:p-0',
  content: 'p-4 w-full min-h-[18vh] max-h-[92vh] max-w-3xl rounded-md mobile:rounded-none',
  body: 'p-0 bg-transparent h-full w-full',
};

const SearchBoxContainer = ({
  brandId,
  brandSlug,
  isBuyerBrandPage = false,
  isSellerBrandPage = false,
  isCityBeachUser = false,
}: {
  brandId?: number;
  brandSlug?: string;
  isBuyerBrandPage?: boolean;
  isSellerBrandPage?: boolean;
  isCityBeachUser?: boolean;
}) => {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <PlaceholderBox
        onClick={open}
        isBuyerBrandPage={isBuyerBrandPage}
        isSellerBrandPage={isSellerBrandPage}
      />
      <Modal
        opened={opened}
        onClose={close}
        radius={0}
        centered={false}
        size="auto"
        classNames={styles}
        transitionProps={{ transition: 'slide-down' }}
      >
        <div className="flex h-full w-full justify-center px-5 pb-4 pt-11 tablet:px-5">
          <SearchBox
            closeModal={close}
            brandId={brandId}
            brandSlug={brandSlug}
            isBuyerBrandPage={isBuyerBrandPage}
            isSellerBrandPage={isSellerBrandPage}
            isCityBeachUser={isCityBeachUser}
          />
        </div>
      </Modal>
    </>
  );
};

export default SearchBoxContainer;
