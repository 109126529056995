import { Text } from '@ui/core';
import { cx } from '@ui/utils';
import { IconSearch } from '@ui/icons';
import { ICON_SIZE } from '@ui/constants';

const PlaceholderBox = ({
  onClick,
  isBuyerBrandPage,
  isSellerBrandPage,
}: {
  onClick: () => void;
  isBuyerBrandPage: boolean;
  isSellerBrandPage: boolean;
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(
        'flex h-[34px] w-full min-w-[200px] flex-1 items-center rounded-full border border-andisor-pink bg-white py-0.5 text-sm text-andisor-navy outline-none transition-all'
      )}
    >
      <Text size="c1" className="ml-4 flex-1 whitespace-nowrap text-left">
        {isBuyerBrandPage || isSellerBrandPage ? 'Search store...' : 'Discover +1000 products!'}
      </Text>
      <span className="mr-1 flex h-7 w-7 items-center justify-center rounded-full bg-andisor-pink text-white">
        <IconSearch size={ICON_SIZE.xs} />
      </span>
    </button>
  );
};

export default PlaceholderBox;
