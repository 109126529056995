import { useAuth } from '@hooks/useAuth';
import { ANDISOR_LEGAL_LINKS, ROLES } from '@constants';

const FooterCompact = () => {
  const { user } = useAuth();

  return (
    <footer className="flex items-center justify-between py-6 text-xs md:flex-col md:items-start md:gap-6">
      <div className="flex-1">
        <p
          className="leading-relaxed"
          style={{
            background: `linear-gradient(91.92deg, rgb(var(--color-red-7)) -26.26%, rgb(var(--color-pink-4)) 38.19%, rgb(var(--color-blue-6)) 91.58%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          We connect thriving, sustainable fashion businesses.
          <br />
          We unlock ways for retailers to collaborate with their suppliers.
          <br />
          We harness the power of technology and build thriving, sustainable businesses.
        </p>
      </div>
      <div className="flex gap-24">
        <div className="flex flex-col gap-1">
          <a
            href={
              user && user.role === ROLES.BRAND
                ? ANDISOR_LEGAL_LINKS.SELLER_TERMS_AND_CONDITION
                : ANDISOR_LEGAL_LINKS.BUYER_TERMS_AND_CONDITION
            }
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>
          <a
            href={ANDISOR_LEGAL_LINKS.PRIVACY_POLICY}
            className="hover:underline"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
        <div className="flex flex-col gap-1">
          <span>© Andisor 2024</span>
          <span>All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default FooterCompact;
