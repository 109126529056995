import { Fragment, useRef } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDebouncedState } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import { Loader, ScrollArea, Text, UnstyledButton } from '@ui/core';
import { IconSearch } from '@ui/icons';
import { ICON_SIZE } from '@ui/constants';
import {
  updateBreadcrumb,
  updateFilterChangeType,
  updateSelectedFilters,
} from '@redux/browse/browseSlice';
import { useAppDispatch } from '@redux/store';
import { api } from '@api';
import { useAuth } from '@hooks/useAuth';
import { ROUTES } from '@constants/route';

const SEARCH_DEBOUNCE_DELAY = 600;
const SEARCH_RESULTS_LIMIT = 5;

const SearchBox = ({
  closeModal,
  isBuyerBrandPage,
  isSellerBrandPage,
  brandId,
  brandSlug,
  isCityBeachUser,
}: {
  closeModal: () => void;
  isBuyerBrandPage: boolean;
  isSellerBrandPage: boolean;
  brandId: number | undefined;
  brandSlug: string | undefined;
  isCityBeachUser: boolean;
}) => {
  const dispatch = useAppDispatch();

  const { isAuthenticated, user } = useAuth();
  const router = useRouter();

  const [value, setValue] = useDebouncedState('', SEARCH_DEBOUNCE_DELAY);
  const inputRef = useRef<HTMLInputElement>(null);

  const retailerId = user?.activeRetailer?.id as number;

  const productsQuery = useQuery({
    queryKey: [api.common.search.globalSearch.queryKey, { searchTerm: value, retailerId, brandId }],
    queryFn: () =>
      api.common.search.globalSearch.query({
        searchTerm: value,
        take: SEARCH_RESULTS_LIMIT,
        retailerId,
        brandId,
      }),
    onError: err => console.log({ err }),
    enabled: value.length > 2,
    cacheTime: 0,
  });

  const navigateToSearchPage = () => {
    const text = inputRef.current?.value || '';
    if (text.trim().length > 0) {
      closeModal();
      if (isBuyerBrandPage || isSellerBrandPage || isCityBeachUser) {
        dispatch(updateFilterChangeType(''));
        dispatch(
          updateBreadcrumb([
            {
              name: '',
              targetAudiencePaths: [],
              userCategoryPaths: [],
              productCategoryPaths: [],
              children: [],
            },
          ])
        );
        dispatch(
          updateSelectedFilters({
            productCategories: [] as string[],
            targetAudiences: [] as string[],
            userCategories: [] as string[],
            productTypes: [],
            colours: [],
            scents: [],
          })
        );
      }
      if (isBuyerBrandPage) {
        router.push({
          pathname: `${ROUTES.BUYERS.BROWSE}/brand/${brandSlug}`,
          query: { searchTerm: text },
        });
      } else if (isSellerBrandPage) {
        router.push({
          pathname: `${ROUTES.SELLERS.PRODUCTS_SEARCH_PAGE(brandId!)}`,
          query: { searchTerm: text },
        });
      } else {
        router.push({ pathname: ROUTES.COMMON.SEARCH, query: { searchTerm: text } });
      }
    }
  };

  const renderOptions = () => {
    const sections = [];

    if (productsQuery.data) {
      if (productsQuery.data?.brands?.length > 0) {
        const section = (
          <Fragment key="brands">
            <Text className="my-2 font-semibold">Brands</Text>
            {productsQuery.data?.brands?.map((brand, idx) => {
              const path = `${ROUTES.COMMON.BRANDS}/${brand.slug}`;
              return (
                <Link
                  key={'brand' + idx}
                  href={path}
                  className="group mb-3 flex w-full items-center gap-3"
                  onClick={() => closeModal()}
                >
                  <div className="relative h-10 w-10 shrink-0 overflow-hidden rounded-full shadow-md">
                    <Image
                      src={brand.logoUrl || '/assets/images/image_unavailable.jpg'}
                      alt={brand.name}
                      width={32}
                      height={32}
                      style={{
                        maxWidth: 'unset',
                      }}
                      className="h-10 w-10 shrink-0 rounded-full object-contain object-center"
                    />
                  </div>
                  <div>
                    <span className="text-sm hover:underline group-hover:underline">
                      {brand.name}
                    </span>
                  </div>
                </Link>
              );
            })}
          </Fragment>
        );
        sections.push(section);
      }
      if (productsQuery.data?.products?.length > 0) {
        const section = (
          <Fragment key="products">
            <Text className="my-2 font-semibold">Products</Text>
            {productsQuery.data?.products?.map((product, idx) => {
              const path = `${ROUTES.COMMON.PRODUCT}/${product.productId}`;
              return (
                <a
                  key={'product' + idx}
                  href={isAuthenticated ? path : `${ROUTES.NEXT_API.AUTH.LOGIN}?returnTo=${path}`}
                  className="group mb-3 flex w-full items-center gap-3"
                  onClick={() => closeModal()}
                >
                  <div className="relative h-14 w-10 shrink-0 overflow-hidden rounded shadow-md">
                    <div
                      className="h-14 w-10 bg-center bg-no-repeat"
                      style={{
                        backgroundImage: `url(${
                          product.variants[0].images?.[0] || '/assets/images/image_unavailable.jpg'
                        })`,
                        backgroundSize: '100% 100%',
                        filter: 'blur(20px)',
                      }}
                    ></div>
                    <Image
                      src={
                        product.variants[0].images?.[0] || '/assets/images/image_unavailable.jpg'
                      }
                      alt={product.name}
                      width={32}
                      height={32}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: 'unset',
                      }}
                      className="h-14 w-10 shrink-0 rounded object-contain object-center"
                    />
                  </div>
                  <div className="flex flex-col gap-0.5 overflow-hidden">
                    <span className="text-sm hover:underline group-hover:underline">
                      {product.name}
                    </span>
                    <span className="text-xs text-andisor-gray-600">{product.brandName}</span>
                  </div>
                </a>
              );
            })}
          </Fragment>
        );
        sections.push(section);
      }

      if (productsQuery.data?.categories?.length > 0) {
        const section = (
          <Fragment key="categories">
            <Text className="my-2 font-semibold">Categories</Text>
            {productsQuery.data?.categories?.map((category, idx) => {
              const navPath = isBuyerBrandPage
                ? `${ROUTES.BUYERS.BROWSE}/brand/${brandSlug}`
                : isSellerBrandPage
                  ? `${ROUTES.SELLERS.PRODUCTS_SEARCH_PAGE(brandId!)}`
                  : ROUTES.COMMON.SEARCH +
                    `?productCategoryPaths=${category.path}&searchTerm=${value}`;

              return (
                <Link
                  key={'category' + idx}
                  href={navPath}
                  className="group mb-3 flex w-full items-center gap-3"
                  onClick={() => {
                    closeModal();
                    if (isBuyerBrandPage || isSellerBrandPage || isCityBeachUser) {
                      dispatch(updateFilterChangeType(''));
                      dispatch(
                        updateBreadcrumb([
                          {
                            name: category.levels.at(-1) as string,
                            targetAudiencePaths: [],
                            userCategoryPaths: [],
                            productCategoryPaths: [category.path],
                            children: [],
                          },
                        ])
                      );
                      dispatch(
                        updateSelectedFilters({
                          productCategories: [category.path] as string[],
                          targetAudiences: [] as string[],
                          userCategories: [] as string[],
                          productTypes: [],
                          colours: [],
                          scents: [],
                        })
                      );
                    }
                  }}
                >
                  <div className="flex flex-col gap-0.5 overflow-hidden">
                    <span className="text-sm hover:underline group-hover:underline">
                      {category.levels[category.levels.length - 1]}
                    </span>
                    <span className="text-xs text-andisor-gray-600">
                      {category.levels.slice(0, category.levels.length - 1).join(' / ')}
                    </span>
                  </div>
                </Link>
              );
            })}
          </Fragment>
        );
        sections.push(section);
      }
    }

    return sections;
  };

  const isEmpty =
    productsQuery.data &&
    productsQuery.data.products.length === 0 &&
    productsQuery.data.categories.length === 0 &&
    productsQuery.data.brands.length === 0;

  return (
    <div className="flex h-full flex-1 flex-col">
      <div className="flex w-full items-center bg-white text-base text-andisor-navy">
        <input
          ref={inputRef}
          type="text"
          defaultValue={value}
          className="w-full flex-1 border-b pb-3 focus:border-andisor-pink focus:outline-none"
          aria-label="search"
          placeholder={
            isBuyerBrandPage || isSellerBrandPage
              ? 'Search across products and categories!'
              : 'Search across brands, products, and categories!'
          }
          onChange={event => {
            setValue(event.currentTarget.value);
          }}
          autoFocus
          autoComplete="off"
          spellCheck="false"
          data-autofocus
          onKeyUp={event => {
            if (event.key === 'Enter') {
              navigateToSearchPage();
            }
          }}
        />
        <UnstyledButton
          className="ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-andisor-pink text-white"
          onClick={navigateToSearchPage}
        >
          <IconSearch size={ICON_SIZE.sm} />
        </UnstyledButton>
      </div>
      {productsQuery.isFetching ? (
        <div className="mt-3 flex justify-center">
          <Loader size={30} className="mr-2" />
        </div>
      ) : (
        <>
          {!productsQuery.isError && productsQuery.data && (
            <ScrollArea.Autosize mah="60vh" type="auto" className="flex-1 pt-5">
              {isEmpty ? (
                <div className="flex justify-center text-andisor-gray-500">
                  No results available.
                </div>
              ) : (
                renderOptions()
              )}
            </ScrollArea.Autosize>
          )}
        </>
      )}
      {productsQuery.isError && (
        <div className="mt-3 flex justify-center text-andisor-gray-500">Something went wrong.</div>
      )}
    </div>
  );
};

export default SearchBox;
