export const ACS_STAGING_BRAND_ID = 175;

export const STAGING_PARENT_IDS = {
  // (retailer -> 'Kidstuff Company'', 'kidstuff@andisor.com')
  KIDSTUFF_USERS: [866],
  // (retailer -> 'Citybeach', 'ladat44307@konetas.com')
  CITYBEACH_USERS: [926],
  // (brand -> 'Adidas Combat Sports', 'adidascombatsports@andisor.com')
  COMBAT_SPORTS_USERS: [172],
};

// any new dynamic landing page user config should be added here only if such page has been created first on Crystallize
export const LANDING_PAGE_STAGING_PATH_CONFIG_MAP: { [key: string]: number[] } = {
  kidstuff: STAGING_PARENT_IDS.KIDSTUFF_USERS,
};
