export const ACS_PROD_BRAND_ID = 263;

export const PROD_PARENT_IDS = {
  // (retailer -> 'Kidstuff', 'kidstuff@andisor.com')
  KIDSTUFF_USERS: [935],
  // (retailer -> 'fn@andisor.com', 'scalzofoods@andisor.com', 'pdontschuk@redzed.com', 'alice.taumalolo@arup.com', cityofmelbourne), (brand -> david@tbng.au)
  TBNG_USERS: [858, 895, 900, 934, 137, 961],
  // (retailer -> 'citybeach@andisor.com')
  CITYBEACH_USERS: [958],
  // (brand -> 'stonehive@andisor.com') (retailer -> 'tom@andisor.com')
  COMBAT_SPORTS_USERS: [258, 798],
};

// any new dynamic landing page user config should be added here only if such page has been created first on Crystallize
export const LANDING_PAGE_PROD_PATH_CONFIG_MAP: { [key: string]: number[] } = {
  kidstuff: PROD_PARENT_IDS.KIDSTUFF_USERS,
  citybeach: PROD_PARENT_IDS.CITYBEACH_USERS,
};
