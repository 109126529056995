import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { ActionIcon, Popover, ScrollArea } from '@ui/core';
import { cx } from '@ui/utils';
import { IconBell, IconCircleCheckFilled } from '@ui/icons';
import { api } from '@api';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';

type NotificationType = {
  id: number;
  message: string;
  logo: string;
  markAsRead: boolean;
};

const POLLING_INTERVAL = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
const PAGE_SIZE = 1000;

const Notification = () => {
  const queryClient = useQueryClient();
  const { isCombatSportsUser } = useIsCombatSportsUser();
  const [IsShowAllNotification, setIsShowAllNotification] = useState<boolean>(false);
  // We can remove this readNotificationId state once we remove the mark as read button and implement the actual notification flow
  const [readNotificationId, setIsReadNotificationId] = useState<number>(0); // to apply the loader on mark as read button click

  const getNotification = useQuery({
    queryKey: [api.sellers.notification.getNotifications.queryKey],
    queryFn: () => {
      return api.sellers.notification.getNotifications.query({
        take: PAGE_SIZE,
        skip: 0,
      });
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  // Use useEffect to trigger refetch every 2 hours
  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotification.refetch();
    }, POLLING_INTERVAL);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [getNotification]);

  const notificationList = getNotification?.data?.result || [];
  const showNotificationList = notificationList?.notifications?.length;

  const updateNotificationStatus = useMutation({
    mutationFn: api.sellers.notification.readNotification.mutation,
    onSuccess: () => {
      queryClient.invalidateQueries([api.sellers.notification.getNotifications.queryKey]);
    },
  });

  const handleNotification = (viewedNotification: NotificationType) => {
    updateNotificationStatus.mutate([viewedNotification.id]);
    setIsReadNotificationId(viewedNotification.id);
  };

  const showAllNotification = () => {
    setIsShowAllNotification(true);
  };

  return (
    <Popover
      classNames={{ dropdown: 'ml-[-3px]', arrow: 'ml-1' }}
      width={340}
      offset={10}
      zIndex={1000}
      position="bottom"
      withArrow
      shadow="md"
    >
      <Popover.Target>
        <div className="relative flex">
          <IconBell
            className={cx('cursor-pointer', isCombatSportsUser && 'text-black')}
            size={24}
            stroke={1.5}
          />
          {notificationList?.count > 0 && (
            <div className="absolute right-[-6px] top-[-8px] flex h-[19px] w-[19px] items-center justify-center overflow-hidden rounded-full bg-andisor-red text-center text-[13px] font-bold">
              <span className={`${notificationList?.count <= 5 && 'absolute right-[6px]'}`}>
                {notificationList?.count > 5 ? '5+' : notificationList?.count}
              </span>
            </div>
          )}
        </div>
      </Popover.Target>
      <Popover.Dropdown className="m-0 p-0">
        {showNotificationList ? (
          <ScrollArea.Autosize mah="42vh" type="auto" className="flex w-full text-andisor-navy">
            <ul>
              {notificationList?.notifications?.map((notification, index) => {
                const showIntialNotification = IsShowAllNotification ? true : index < 4;
                return (
                  <React.Fragment key={notification.id}>
                    {showIntialNotification && (
                      <>
                        <li
                          key={notification.id}
                          className="flex items-center justify-between gap-2 bg-white py-2 pl-6 pr-2 text-sm"
                        >
                          <div className="w-[250px] text-left">{notification.message}</div>
                          <div className="justify-end">
                            <ActionIcon
                              color="pink"
                              variant="plain"
                              label="Mark as read"
                              onClick={() => handleNotification(notification)}
                              loading={
                                (updateNotificationStatus?.isLoading ||
                                  getNotification.isFetching) &&
                                readNotificationId === notification.id
                              }
                              withTooltip={true}
                            >
                              <IconCircleCheckFilled size={21} stroke={1.5} />
                            </ActionIcon>
                          </div>
                        </li>
                        <hr className="h-px border-0 bg-andisor-gray" />
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </ul>
            {!IsShowAllNotification && showNotificationList > 4 && (
              <div className="flex w-full justify-center">
                <button
                  type="button"
                  className="m-auto my-2 text-andisor-blue"
                  onClick={showAllNotification}
                >
                  View All
                </button>
              </div>
            )}
          </ScrollArea.Autosize>
        ) : (
          <p className="flex w-full p-2 text-black/80 transition-colors">No new notifications</p>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

export default Notification;
