import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { type QueryError } from 'src/types/common';
import { api } from '@api';
import { useAuth } from '@hooks/useAuth';
import { ACS_DEV_BRAND_ID, DEV_PARENT_IDS } from '@data/dev/devUsers';
import { ACS_PROD_BRAND_ID, PROD_PARENT_IDS } from '@data/prod/prodUsers';
import { ACS_STAGING_BRAND_ID, STAGING_PARENT_IDS } from '@data/staging/stagingUsers';
import { ROLES } from '@constants';

const getParentIdConfigForEnv = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'dev': {
      return DEV_PARENT_IDS.COMBAT_SPORTS_USERS;
    }
    case 'staging': {
      return STAGING_PARENT_IDS.COMBAT_SPORTS_USERS;
    }
    case 'demo': {
      return null;
    }
    case 'prod': {
      return PROD_PARENT_IDS.COMBAT_SPORTS_USERS;
    }
    default: {
      return null;
    }
  }
};

export const getACSBrandIdForEnv = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'dev': {
      return ACS_DEV_BRAND_ID;
    }
    case 'staging': {
      return ACS_STAGING_BRAND_ID;
    }
    case 'demo': {
      return null;
    }
    case 'prod': {
      return ACS_PROD_BRAND_ID;
    }
    default: {
      return null;
    }
  }
};

export const checkCombatSportsUser = (parentId: number | undefined) => {
  if (!parentId) return false;

  const parentIdConfig = getParentIdConfigForEnv();
  if (!parentIdConfig) return false;

  return parentIdConfig.includes(parentId);
};

const useIsCombatSportsUser = () => {
  const { isAuthenticated, user } = useAuth();

  const retailerId = user?.activeRetailer?.id as number;
  const isRetailer = user?.role === ROLES.RETAILER;

  const isWithinHardcodedList =
    isAuthenticated &&
    checkCombatSportsUser(isRetailer ? user?.parentRetailer?.id : user?.parentBrand?.id);

  const acsBrandId = getACSBrandIdForEnv();
  const checkCustomerForBrandQuery = useQuery({
    queryKey: [api.buyers.orderForm.getOrderForms.queryKey, { retailerId, brandId: acsBrandId }],
    queryFn: () =>
      api.management.retailer.checkCustomerForBrand.query({
        retailerId,
        brandId: acsBrandId!,
      }),
    onError: (err: QueryError) => {
      toast.error(err?.response?.data?.message ?? 'Something went wrong');
    },
    enabled: acsBrandId !== null && isRetailer && !isWithinHardcodedList,
  });

  const isCombatSportsUser = isWithinHardcodedList || checkCustomerForBrandQuery.data?.isCustomer;

  return {
    isCombatSportsUser: !!isCombatSportsUser,
  };
};

export default useIsCombatSportsUser;
