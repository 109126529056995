import { useAuth } from './useAuth';
import { usePocFeatures } from './usePocFeatures';

const useDropshipCheck = () => {
  const { user } = useAuth();
  const { isPocFeaturesEnabled } = usePocFeatures();

  return {
    isDropshipFeatureEnabled: isPocFeaturesEnabled,
    isRetailerEligibleToDropship: isPocFeaturesEnabled && user?.parentRetailer?.isDropshipEligible,
  };
};

export default useDropshipCheck;
