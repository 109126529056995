import { cx } from '@ui/utils';
import { IconChevronRight } from '@ui/icons';
import {
  updateBreadcrumb,
  updateFilterChangeType,
  updateSelectedFilters,
} from '@redux/browse/browseSlice';
import { useAppDispatch } from '@redux/store';
import { NavigationTreeType } from '@api/common/browse';
import { ROUTES } from '@constants/route';
import NavLink from './../NavLink';
import styles from './MenuItem.module.css';

type DynamicMenuItemProps = {
  depth?: number;
  isCityBeachUser?: boolean;
  isKidStuffUser?: boolean;
  isCombatSportsUser?: boolean;
  navigationTreeItem?: NavigationTreeType;
  parentName: string;
  brandSlug: string;
  parentPath?: string | null;
  navigationBreadcrumb: NavigationTreeType[];
  basePath: string;
  isBuyerBrandPage: boolean;
};

const DynamicMenuItem = ({
  depth = 0,
  isCityBeachUser = false,
  isKidStuffUser = false,
  isCombatSportsUser = false,
  navigationTreeItem,
  parentName,
  brandSlug,
  navigationBreadcrumb,
  parentPath = null,
  basePath,
  isBuyerBrandPage,
}: DynamicMenuItemProps) => {
  const dispatch = useAppDispatch();
  const hasChildren = (navigationTreeItem?.children?.length || 0) > 0 && depth < 2;

  const currentPath = parentPath
    ? `${parentPath}/${navigationTreeItem?.name}`.toLowerCase()
    : navigationTreeItem?.name?.toLowerCase() ?? null;

  const renderMenuTreeItem = (menuChildren: null | JSX.Element = null) => {
    return (
      <div
        key={parentName + navigationTreeItem?.name}
        className={cx(styles.menuContainer, 'py-2', {
          'px-4 text-black/80 transition-colors hover:bg-andisor-blue-50 hover:text-andisor-blue':
            depth > 0,
        })}
      >
        <NavLink
          className="flex items-center justify-between"
          href={{
            pathname:
              isCityBeachUser && !isBuyerBrandPage
                ? `${ROUTES.BUYERS.BROWSE}/${currentPath}`
                : basePath,
          }}
          highlightActiveLink={depth === 0}
          highlightColor={
            isCityBeachUser || isKidStuffUser || isCombatSportsUser
              ? 'text-andisor-blue'
              : undefined
          }
          onClick={() => {
            dispatch(updateFilterChangeType(''));
            dispatch(updateBreadcrumb(navigationBreadcrumb));
            dispatch(
              updateSelectedFilters({
                productCategories: (navigationTreeItem?.productCategoryPaths || []) as string[],
                targetAudiences: (navigationTreeItem?.targetAudiencePaths || []) as string[],
                userCategories: (navigationTreeItem?.userCategoryPaths || []) as string[],
                productTypes: [],
                colours: [],
                scents: [],
              })
            );
          }}
        >
          <span className={cx(depth === 0 && 'whitespace-nowrap')}>{navigationTreeItem?.name}</span>
          {depth > 0 && hasChildren && <IconChevronRight width={15} stroke={1.8} />}
        </NavLink>
        {menuChildren}
      </div>
    );
  };

  if (hasChildren) {
    return renderMenuTreeItem(
      <div className={styles.menuContent}>
        <div
          className={cx(
            'min-w-[230px] cursor-pointer rounded-[6px] bg-white py-1 text-sm text-andisor-navy shadow-lg',
            {
              'mt-2': depth === 0,
              'ml-2': depth === 1,
            }
          )}
        >
          {navigationTreeItem?.children?.map(childItem => (
            <DynamicMenuItem
              key={childItem.name}
              parentName={childItem.name}
              parentPath={currentPath}
              depth={depth + 1}
              isCityBeachUser={isCityBeachUser}
              isKidStuffUser={isKidStuffUser}
              isCombatSportsUser={isCombatSportsUser}
              navigationTreeItem={childItem}
              brandSlug={brandSlug}
              navigationBreadcrumb={[...navigationBreadcrumb, childItem]}
              basePath={basePath}
              isBuyerBrandPage={isBuyerBrandPage}
            />
          ))}
        </div>
      </div>
    );
  }

  return renderMenuTreeItem();
};

export default DynamicMenuItem;
