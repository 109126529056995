import { NavigationTree } from '@redux/common/commonSlice';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';
import { ROUTES } from '@constants/route';
import DynamicMenuItem from './DynamicMenuItem';
import MenuItem from './MenuItem';

type DynamicHoverMenuProps = {
  navigationTree: NavigationTree;
  isCityBeachUser?: boolean;
  isKidStuffUser?: boolean;
  basePath?: string;
  isBuyerBrandPage?: boolean;
};

const DynamicHoverMenu = ({
  isCityBeachUser = false,
  isKidStuffUser = false,
  navigationTree,
  basePath = `${ROUTES.BUYERS.BROWSE}/brand/${navigationTree?.brandSlug}`,
  isBuyerBrandPage = false,
}: DynamicHoverMenuProps) => {
  const { isCombatSportsUser } = useIsCombatSportsUser();

  return (
    <div className="flex gap-10 text-sm tablet:hidden">
      {navigationTree?.data.map(item => (
        <DynamicMenuItem
          key={item.name}
          parentName={item.name}
          navigationTreeItem={item}
          isCityBeachUser={isCityBeachUser}
          isKidStuffUser={isKidStuffUser}
          isCombatSportsUser={isCombatSportsUser}
          brandSlug={navigationTree.brandSlug}
          navigationBreadcrumb={[item]}
          basePath={basePath}
          isBuyerBrandPage={isBuyerBrandPage}
        />
      ))}
      {isCityBeachUser && (
        <MenuItem
          isCityBeachUser
          item={{
            name: 'Brands',
            path: `${ROUTES.COMMON.BRANDS}`,
            children: [],
          }}
        />
      )}
    </div>
  );
};

export default DynamicHoverMenu;
