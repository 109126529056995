import { z } from 'zod';
import { cx } from '@ui/utils';
import { IconChevronRight } from '@ui/icons';
import { getHeroNavigationSchema } from '@api/common/browse';
import { ROUTES } from '@constants/route';
import NavLink from './../NavLink';
import styles from './MenuItem.module.css';

type MenuItemProps = {
  item: z.infer<typeof getHeroNavigationSchema>[number];
  depth?: number;
  isCityBeachUser?: boolean;
  isKidStuffUser?: boolean;
  isCombatSportsUser?: boolean;
};

const MenuItem = ({
  item,
  depth = 0,
  isCityBeachUser = false,
  isKidStuffUser = false,
  isCombatSportsUser = false,
}: MenuItemProps) => {
  const hasChildren = item?.children?.length > 0 && depth < 2;

  const renderMenuItem = (menuChildren = null) => {
    return (
      <div
        key={item.path}
        className={cx(styles.menuContainer, 'py-2', {
          'px-4 text-black/80 transition-colors hover:bg-andisor-blue-50 hover:text-andisor-blue':
            depth > 0,
        })}
      >
        <NavLink
          className="flex items-center justify-between"
          href={item.path.replace('/navigation', ROUTES.BUYERS.BROWSE)}
          highlightActiveLink={depth === 0}
          highlightColor={
            isCityBeachUser || isKidStuffUser || isCombatSportsUser
              ? 'text-andisor-blue'
              : undefined
          }
        >
          <span className={cx(depth === 0 && 'whitespace-nowrap')}>{item.name}</span>
          {depth > 0 && hasChildren && <IconChevronRight width={15} stroke={1.8} />}
        </NavLink>
        {menuChildren}
      </div>
    );
  };

  if (hasChildren) {
    return renderMenuItem(
      <div className={styles.menuContent}>
        <div
          className={cx(
            'min-w-[230px] cursor-pointer rounded-[6px] bg-white py-1 text-sm text-andisor-navy shadow-lg',
            {
              'mt-2': depth === 0,
              'ml-2': depth === 1,
            }
          )}
        >
          {item.children?.map(childItem => (
            <MenuItem
              key={childItem.path}
              item={childItem}
              depth={depth + 1}
              isCityBeachUser={isCityBeachUser}
              isKidStuffUser={isKidStuffUser}
              isCombatSportsUser={isCombatSportsUser}
            />
          ))}
        </div>
      </div>
    );
  }

  return renderMenuItem();
};

export default MenuItem;
