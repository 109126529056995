import { UrlObject } from 'node:url';
import React, { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cx } from '@ui/utils';

type NavLinkProps = {
  href: string | UrlObject;
  children: React.ReactNode;
  className?: string;
  highlightActiveLink?: boolean;
  highlightColor?: string | undefined;
  onClick?: () => void;
};

const NavLink = ({
  href,
  children,
  className = '',
  highlightActiveLink = false,
  highlightColor = 'text-andisor-natural',
  onClick,
}: NavLinkProps) => {
  const { asPath: currentPath } = useRouter();

  const isActiveLink = useMemo(() => {
    if (typeof href === 'string') {
      if (href === '/') return currentPath === href;
      return currentPath.includes(href);
    }
  }, [href, currentPath]);

  return (
    <Link
      href={href}
      onClick={e => {
        e.stopPropagation();
        if (onClick) onClick();
      }}
      className={cx(className, {
        [highlightColor]: highlightActiveLink && isActiveLink,
        'transition-colors hover:text-andisor-natural': highlightActiveLink,
      })}
    >
      {children}
    </Link>
  );
};

export default NavLink;
