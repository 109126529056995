import { useAuth } from '@hooks/useAuth';
import { DEMO_PARENT_IDS } from '@data/demo/demoUsers';
import { ROLES } from '@constants';

const getParentIdConfigForEnv = () => {
  switch (process.env.NEXT_PUBLIC_ENV) {
    case 'dev': {
      return null;
    }
    case 'staging': {
      return null;
    }
    case 'demo': {
      return DEMO_PARENT_IDS.NEW_BALANCE_USERS;
    }
    case 'prod': {
      return null;
    }
    default: {
      return null;
    }
  }
};

export const checkNewBalanceUser = (parentId: number | undefined) => {
  if (!parentId) return false;

  const parentIdConfig = getParentIdConfigForEnv();
  if (!parentIdConfig) return false;

  return parentIdConfig.includes(parentId);
};

const useIsNewBalanceUser = () => {
  const { isAuthenticated, user } = useAuth();

  const isRetailer = user?.role === ROLES.RETAILER;

  const isNewBalanceUser =
    isAuthenticated &&
    checkNewBalanceUser(isRetailer ? user?.parentRetailer?.id : user?.parentBrand?.id);

  return {
    isNewBalanceUser: !!isNewBalanceUser,
  };
};

export default useIsNewBalanceUser;
